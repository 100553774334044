import React, { useState, useEffect } from 'react';
import { format } from 'date-fns'
import { css } from '@mui/system';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Chip from '@mui/material/Chip';
import { useParams } from 'react-router-dom';
import Order from 'common/models/order';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// ICONS
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import Modal from '@mui/material/Modal';
import Vendor from 'common/models/vendor'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import BalanceIcon from '@mui/icons-material/Balance';
import AddIcon from '@mui/icons-material/Add';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import MailIcon from '@mui/icons-material/Mail';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import Alert from '@mui/material/Alert';
import "./style.scss";
import { formatPhoneNumber } from 'common/utils';
import moment from 'moment';
import getJWT from 'common/authentication/jwt';

const TableContainerStyled = styled(TableContainer)(css`
  .MuiTableCell-head .MuiSvgIcon-root {
    color: #5a5a5b
  }
  overflow-x:hidden !important;
`);

const TableBodyStyled = styled(TableBody)(css`
  th {
    padding-left: 16px !important;
  }
`);
const TableCellStyled = styled(TableCell)(css`
  border: 0px !important;
  padding: 3px 6px;
  font-size: 14px;

  .MuiStep-root {
    margin-top: 14px;
  }
  .MuiStepLabel-label {
    font-size: 10px;
    padding-top: 4px;
    margin-top: 4px !important;
  }
`);

const ButtonMargin = styled(Button)(css`
    margin: 10px
`);

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });


const VendorRemovalConfirmationModal = ({ open, handleClose }) => {
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={() => handleClose(false)}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 420,
            borderRadius: '5px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3
          }}>
          <Typography sx={{ fontWeight: 'bold' }}>Remove Vendor</Typography>
          <Typography sx={{ fontSize: '14px' }}>Remove the selected vendor from this assignment?</Typography>
          <Box sx={{ textAlign: 'center', paddingTop: '24px' }}>
            <Button onClick={() => handleClose(false)} sx={{ marginRight: '24px' }}>Cancel</Button>
            <Button onClick={() => handleClose(true)} variant="contained">Remove Vendor</Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

const FirmRemovalConfirmationModal = ({ open, handleClose }) => {
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={() => handleClose(false)}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 420,
            borderRadius: '5px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3
          }}>
          <Typography sx={{ fontWeight: 'bold' }}>Remove Firm</Typography>
          <Typography sx={{ fontSize: '14px' }}>Remove the selected firm from this assignment?</Typography>
          <Box sx={{ textAlign: 'center', paddingTop: '24px' }}>
            <Button onClick={() => handleClose(false)} sx={{ marginRight: '24px' }}>Cancel</Button>
            <Button onClick={() => handleClose(true)} variant="contained">Remove Vendor</Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default function OrderPartsSubPage({ setSelectedCompanyId, selectedCompanyId, orderid, setShowAttorneyDetails, setShowFirmDetails, partId, setPartId, activePipeline }) {
  const { orderid: orderIdParam } = useParams();
  orderid = Number(orderid || orderIdParam);

  const [showRemovalModal, setShowRemovalModal] = useState(false);
  const [showRemovalFirmModal, setShowRemovalFirmModal] = useState(false);
  const [partIndex, setPartIndex] = useState();
  const [unassignPart, setUnassignPart] = useState({});
  const [unassignFirm, setUnassignFirm] = useState({});
  let [data, setData] = useState({ parts: [] });
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarType, setSnackbarType] = useState('success')
  const [alertContent, setAlertConent] = useState('')
  const [downloadprogress, setDownloadprogress] = useState(false);
  const [downloadLink, setDownloadLink] = useState(false);

  const vendor = (new Vendor);
  const order = (new Order);

  const steps = [
    "DescriptionIcon",
    "PersonAddIcon",
    "MailIcon",
    'AssignmentIndIcon',
    'AssignmentTurnedInIcon',
  ];

  const preliminarySteps = [
    "DescriptionIcon",
    "PersonAddIcon",
    "SearchIcon",
    'AssignmentTurnedInIcon',
  ]

  useEffect(async () => {
    if (data?.parts?.length === 0) {
      const order = (new Order);
      const result = await order.getParts(orderid);
      let docData = { doc: [], reject: [], parts: [] }
      result.parts.forEach((rec) => {
        if (rec.part_statusid === 35)
          docData.reject.push(rec);
        else
          docData.doc.push(rec);
        docData.parts.push(rec);
      });
      setData(docData);
    }
  }, [data]);

  useEffect(() => {
    if (partId && selectedCompanyId)
      onAssignFirmConfirmation(partId, selectedCompanyId)
  }, [selectedCompanyId])

  const onAssignFirmConfirmation = async (partid, selectedCompanyId) => {
    const result = await order.assignOrderPartToLawFirm(partid, selectedCompanyId);
    if (result.status == 'success') {
      setAlertConent('Successfully Assigned')
      setSnackbarType('success')
      setShowFirmDetails(false);
      const result = await order.getParts(orderid);
      setData(result);
      setSelectedCompanyId();
    }
    else {
      setShowSnackbar(true)
      setAlertConent('Assign Failure')
      setSnackbarType('error')
    }
  }

  const closeRemovalFirmModal = async (confirmed) => {
    if (confirmed) {
      const result = await vendor.unAssignLawFirm(data.parts[partIndex].partid);
      if (result.status == 'success') {
        const result = await order.getParts(orderid);
        setData(result);
      }
    }
    setShowRemovalFirmModal(false)
  }
  
  const onUnassignFirm = (idx) => {
    setPartIndex(idx)
    setShowRemovalFirmModal(true)
  }
  
   const onAssignFirm = (partid) => {
    setPartId(partid);
    setShowFirmDetails(true);
  }
  
  const closeRemovalModal = async (confirmed) => {
    if (confirmed) {
      const result = await vendor.unassignVendor(data.parts[partIndex].acceptedby, data.parts[partIndex].partid);
      if (result.status == 'success') {
        const result = await order.getParts(orderid);
        setData(result);
      }
    }
    setShowRemovalModal(false)
  }

  const onUnassign = (idx) => {
    setPartIndex(idx)
    setShowRemovalModal(true)
  }

  const onAssign = (partid) => {
    setPartId(partid)
    setShowAttorneyDetails(true)
  }

  const cellContent = (column, row) => {
    const icon_size = '20px';
    const circle_default = <CircleRoundedIcon sx={{ width: icon_size, height: icon_size, fill: '#c4c4c6' }} />;
    const circle_success = <CheckCircleRoundedIcon sx={{ width: icon_size, height: icon_size, fill: '#1de528' }} />;
    const circle_error = <ErrorRoundedIcon sx={{ width: icon_size, height: icon_size, fill: '#e32d16' }} />;

    const prop = row;
    switch (column) {
      case "DescriptionIcon":
        let partstatus;
        const part_prelim = prop?.part_name?.includes('Preliminary Attorney');
        const part_final = prop?.part_name?.includes('Final Attorney');

        if (part_final) {
          partstatus = 'F';
        }
        else if (part_prelim) {
          partstatus = 'P';
        }
        else {
          return <div className="relative"><InsertDriveFileOutlinedIcon sx={{ width: '20px', height: '30px', fill: '#c4c4c6' }} /><span className="subparttype">{partstatus}</span></div>;
        }
        return <div className="relative"><InsertDriveFileOutlinedIcon sx={{ width: '20px', height: '30px', fill: '#c4c4c6' }} /><span className="subparttype">{partstatus}</span></div>
      case "PersonAddIcon":
        return ([1, 2].indexOf(prop?.part_statusid) === -1 && prop.acceptedby > 0 && prop.assignedto > 0) ? circle_success : circle_default;
      case "MailIcon":
        return prop?.outbound_emails_total > 0 ? circle_success : circle_default;
      case "AssignmentIndIcon":
        return [3, 4, 5, 6, 7, 35, 13].indexOf(prop?.part_statusid) !== -1 ? circle_success : circle_default;
      case "AssignmentTurnedInIcon":
        return [6, 7].indexOf(prop?.part_statusid) !== -1 ? circle_success : prop?.part_statusid === 35 ? circle_error : circle_default;
      default:
        return circle_default;
    }
  }


  const getDownloadLink = async (partid) => {

    const jwt = getJWT();

    setDownloadprogress(true);

    const result = await order.getPartDownloadLink(partid);

    setDownloadLink(`/grandcentral/vendor/download/${result.uuid}?token=${jwt}`);
  }

  const DocTable = () => {
    return (<TableContainerStyled sx={{overflow: 'hidden' }}>
      <Table sx={{ ml:'50px' }} aria-label="">
        <TableHead>
          <TableRow className="document-header">
          <TableCell sx={{width:'10%'}}>ORDER DATE</TableCell>
            <TableCell sx={{width:'20%'}}>DOCUMENTS</TableCell>
            <TableCell  className='text-center icon-columns' ><DescriptionIcon/></TableCell>
              <TableCell  className='text-center icon-columns'><PersonAddIcon/></TableCell>
              <TableCell className='text-center icon-columns'><MailIcon/></TableCell>
              <TableCell className='text-center icon-columns'><AssignmentIndIcon/></TableCell>
              <TableCell  className='text-center icon-columns'><AssignmentTurnedInIcon/></TableCell>
            <TableCell sx={{width:'10%'}} >FIRM</TableCell>
            <TableCell sx={{width:'10%'}} >ANALYST</TableCell>
            <TableCell sx={{width:'10%'}} >EMAIL</TableCell>
            <TableCell sx={{width:'10%'}} >PHONE</TableCell>
            <TableCell  sx={{width:'10%'}} >DOC DUE</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBodyStyled>
          {data?.doc.map((row) => (
            <TableRow
              key={row.partid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              className="document-content"
            >
            <TableCellStyled >
                {moment(row.document_ready).format('MM-DD-YYYY')}
              </TableCellStyled>
              <TableCellStyled >
                {row.part_name}
              </TableCellStyled>
               {steps.map((icon)=>{
               return <TableCellStyled className='text-center icon-columns' >{cellContent(icon,row)}</TableCellStyled>
             })}
              <TableCellStyled >
                {row.firm}
              </TableCellStyled>
              <TableCellStyled >
                {(row.acceptedby > 0 || row.assignedto > 0) &&
                  <Box sx={{ position: 'relative' }}>
                    <Chip variant="outlined" onDelete={(event) => {
                      event.stopPropagation();
                      if (row.acceptedby > 0) {
                        onUnassign(row.acceptedby, row.partid);
                      }
                      else if (row.assignedto > 0) {
                        onUnassign(row.assignedto, row.partid);
                      }
                    }
                    } label={`${row.firstname?row.firstname:''} ${row.lastname?row.lastname:''}`} />
                  </Box>
                }
                {((row.acceptedby == 0 && row.assignedto == 0) || row.order_status == 2) && <Button startIcon={<PersonAddIcon />} variant="outlined" onClick={() => onAssign(row.partid)}>Assign</Button>}
              </TableCellStyled>
               <TableCellStyled color="primary"><Link href="#">{row.email}</Link></TableCellStyled>
             <TableCellStyled >{row.phone && formatPhoneNumber(row.phone)}</TableCellStyled>
              <TableCellStyled >{row.completed_date && row.timeline["assign_vendor_dts"] ? "Completed" : row.due_date && format(new Date(row.due_date), 'M/dd/yy')}</TableCellStyled>
              <TableCellStyled >
                {row.close_date}
              </TableCellStyled>
              <TableCellStyled>
                {row.part_status == 'Completed' && 
                  <SimCardDownloadIcon color={'primary'}
                    onClick={(event) => {
                              getDownloadLink(row.partid);
                              event.stopPropagation();
                            }}
                  />}
              </TableCellStyled>
            </TableRow>
          ))}
        </TableBodyStyled>
      </Table>
    </TableContainerStyled>);
  }

  const RejectTable = () => {
    return (<TableContainerStyled>
      <Table sx={{ml:'50px' }} aria-label="">
        <TableHead>
          <TableRow className="document-header">
          <TableCell sx={{width:'10%'}}>ORDER DATE</TableCell>
            <TableCell sx={{width:'20%'}}>REJECTED DOCUMENTS</TableCell>
            <TableCell className='text-center icon-columns' ><DescriptionIcon/></TableCell>
              <TableCell className='text-center icon-columns' ><PersonAddIcon/></TableCell>
              <TableCell className='text-center icon-columns' ><MailIcon/></TableCell>
              <TableCell className='text-center icon-columns' ><AssignmentIndIcon/></TableCell>
              <TableCell className='text-center icon-columns' ><AssignmentTurnedInIcon/></TableCell>
            <TableCell sx={{width:'10%'}} >FIRM</TableCell>
            <TableCell sx={{width:'10%'}} >ANALYST</TableCell>
          <TableCell sx={{width:'10%'}} >EMAIL</TableCell>
            <TableCell sx={{width:'10%'}} >PHONE</TableCell>
            <TableCell  sx={{width:'10%'}} >DOC DUE</TableCell>
          </TableRow>
        </TableHead>
        <TableBodyStyled>
          {data?.reject.map((row) => (
            <TableRow
              key={row.partid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              className="document-content"
            >
            <TableCellStyled >
                {moment(row.document_ready).format('MM/DD/YYYY')}
              </TableCellStyled>
              <TableCellStyled >
                {row.part_name}
              </TableCellStyled>
               {steps.map((icon)=>{
               return <TableCellStyled className='text-center icon-columns' >{cellContent(icon,row)}</TableCellStyled>
             })}
              <TableCellStyled >
                {row.firm}
              </TableCellStyled>
              <TableCellStyled >
               {`${row.firstname?row.firstname:''} ${row.lastname?row.lastname:''}`}
              </TableCellStyled>
               <TableCellStyled color="primary"><Link href="#">{row.email}</Link></TableCellStyled>
              <TableCellStyled >{row.phone && formatPhoneNumber(row.phone)}</TableCellStyled>                                                                                                                               
              <TableCellStyled >{row.completed_date && row.timeline["assign_vendor_dts"] ? "Completed" : row.due_date && format(new Date(row.due_date), 'M/dd/yy')}</TableCellStyled>
              <TableCellStyled >
                {row.part_status == 'Completed' && <SimCardDownloadIcon color={'primary'} />}
              </TableCellStyled>
            </TableRow>
          ))}
        </TableBodyStyled>
      </Table>
    </TableContainerStyled>);
  }

  const PrelimaryTable = () => {
    return (<TableContainerStyled>
      <Table sx={{ ml:'50px' }} aria-label="">
        <TableHead>
          <TableRow className="document-header">
          <TableCell sx={{width:'10%'}}>ORDER DATE</TableCell>
            <TableCell sx={{ width:'20%'}}>DOCUMENTS</TableCell>
             <TableCell  className='text-center icon-columns' ><DescriptionIcon/></TableCell>
              <TableCell className='text-center icon-columns'><PersonAddIcon/></TableCell>
              <TableCell className='text-center icon-columns' ><FindInPageOutlinedIcon/></TableCell>
              <TableCell className='text-center icon-columns' ><AssignmentTurnedInIcon/></TableCell>
            <TableCell sx={{width:'10%'}} >FIRM</TableCell>
            <TableCell sx={{width:'10%'}} >ANALYST</TableCell>
            <TableCell sx={{width:'10%'}} >EMAIL</TableCell>
            <TableCell sx={{width:'10%'}} >PHONE</TableCell>
            <TableCell  sx={{width:'10%'}} >DOC DUE</TableCell>
          </TableRow>
        </TableHead>
        <TableBodyStyled>
          {data?.parts.map((row) => (
            <TableRow
              key={row.partid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              className="document-content"
            >
            <TableCellStyled >
                {moment(row.document_ready).format('MM/DD/YYYY')}
              </TableCellStyled>
              <TableCellStyled >
                {row.part_name}
              </TableCellStyled>
               {preliminarySteps.map((icon)=>{
               return <TableCellStyled className='text-center icon-columns' >{cellContent(icon,row)}</TableCellStyled>
             })}
              <TableCellStyled >
                {row.firm != '' || row.firm !=null?<Button size="small" startIcon={<BalanceIcon />} variant="outlined" onClick={() => onAssignFirm(row.partid)}>Assign</Button> 
                :<Box sx={{ position: 'relative' }}>
                    <Chip variant="outlined" onDelete={(event) => {
                      event.stopPropagation();
                        onUnassignFirm(row.partid);
                    }
                    } label={`${row.firm ? row.firm :''}`} />
                  </Box>}
              </TableCellStyled>
              <TableCellStyled >
                {`${row.firstname?row.firstname:''} ${row.lastname?row.lastname:''}`}
              </TableCellStyled>
              <TableCellStyled color="primary"><Link href="#">{row.email}</Link></TableCellStyled>
              <TableCellStyled >{row.phone && formatPhoneNumber(row.phone)}</TableCellStyled>
              <TableCellStyled >{row.completed_date && row.timeline["assign_vendor_dts"] ? "Completed" : row.due_date && format(new Date(row.due_date), 'M/dd/yy')}</TableCellStyled>
              <TableCellStyled >
                {row.part_status == 'Completed' && <SimCardDownloadIcon color={'primary'} />}
              </TableCellStyled>
            </TableRow>
          ))}
        </TableBodyStyled>
      </Table>
    </TableContainerStyled>);
  }
  return (
    <>
      {downloadprogress && (
        <Dialog
          open={downloadprogress}
          onClose={ (event) => {
            setDownloadprogress(false);
            setDownloadLink(false);
          }}
        >
          { (downloadLink && downloadLink != ""  ?
              <DialogContent sx={{textAlign:"center"}}>
                {downloadLink.indexOf("download/false")>0 ? "Document Not Found" : "Document ready"}<br />
                <Button sx={{marginTop:"15px"}} variant="contained" target="_blank" href={downloadLink} endIcon={<FileDownloadIcon />} disabled={downloadLink.indexOf("download/false")>0}>
                  Download Now
                </Button>
              </DialogContent>
            :
              <DialogContent sx={{textAlign:"center"}}>
                Preparing Document <br /><CircularProgress />
              </DialogContent> 
          ) }
        </Dialog>
      )}
      {activePipeline.id==="preliminary" ? 
      <PrelimaryTable/>: 
      <>
        {data?.doc?.length >0 && <DocTable />}
        {data?.reject?.length >0 && <RejectTable/>}
      </>
      }
      <VendorRemovalConfirmationModal open={showRemovalModal} handleClose={closeRemovalModal} />
      <FirmRemovalConfirmationModal open ={showRemovalFirmModal} handleClose={closeRemovalFirmModal} />
      <Snackbar open={showSnackbar} autoHideDuration={4000} onClose={()=>setShowSnackbar(false) }>
      <Alert onClose={()=>setShowSnackbar(false)} severity={snackbarType} sx={{ width: '100%' }}>
        {alertContent}
      </Alert>
    </Snackbar>
    </>
  );
}
