import { PipelineCollection } from 'common/pipelines/usePipelineCollection';
import PipelineView from './PipelineView';
import ProgressBar from 'common/ui/ProgressBar';

export default function PipelinesSubPage(props) {
  return (
    <PipelineCollection>

      <PipelineCollection.Config
        id="preliminary"
        name="Preliminary Letters"
        source="lightCyclePreliminary"
        // link="/admin/order/{{id}}"
        columns={[
          {
            target : 'date.ordered',
            caption : 'Ordered Date',
            size: '10%',
            format: 'Date',
            className : 'datecolumn'
          },
          {
            target : 'property.fulladdress',
            size : '20%'
          },
          {
            target: null,
            caption: 'Document Part',
            size: '3%',
            icon: 'DescriptionIcon',
            className : 'iconwidth'
          },
          {
            target: null,
              caption: 'Assigned Firm',
              size: '3%',
              icon: 'PersonAddIcon',
              className: 'iconwidth'
          },
          {
            target:null,
            caption:'Analyst started',
            size: '3%',
            icon: 'SearchIcon',
            className : 'iconwidth'
          },
          {
            target: null,
            caption: 'Vendor Completed',
            size: '3%',
            icon: 'AssignmentTurnedInIcon',
            className : 'iconwidth'
          },
          {
            target: 'escrow_num', //'client_reference_num', // COMMENT By ME // TO DO: Escrow number?
            caption: 'Escrow No.',
            size: '10%',
            sortValue: 'client_reference_num',
          },
          {
            target: 'client.name',
            caption: 'Client',
            size : '15%',
            sortValue: [ 'client.name', 'date.ordered' ],
          },
          {
            target: 'date.due',
            caption: 'Due Date',
            size: '10%',
            format: 'Date',
            className : 'datecolumn'
          },
          {
            target : 'admin_assigned.name',
            className : 'orderadmin',
            size : '10%'
          },
          {
            target: null,
            caption: 'Download Document',
            size: '3%',
            icon: 'SimCardDownloadIcon',
            className : 'iconwidth'
          }
        ]}
      />
      <PipelineCollection.Config
        id="final"
        name="Final Letters"
        source="lightCycleFinal"
        // link="/admin/order/{{id}}"
        columns={[
          {
            target : 'date.ordered',
            caption : 'Ordered Date',
            size: '10%',
            format: 'Date',
            className : 'datecolumn'
          },
          {
            target : 'property.fulladdress',
            size : '20%'
          },
          {
            target: null,
            caption: 'Document Part',
            size: '3%',
            icon: 'DescriptionIcon',
            className : 'iconwidth'
          },
          {
            target: null,
            caption: 'Assign Vendor',
            size: '3%',
            icon: 'PersonAddIcon',
            className : 'iconwidth'            
          },          
          {
            target: null,
            caption: 'Vendor Sent',
            size: '3%',
            icon: 'MailIcon',
            className : 'iconwidth'            
          },          
          {
            target: null,
            caption: 'Vendor Pending Acceptance',
            size: '3%',
            icon: 'AssignmentIndIcon',
            className : 'iconwidth'            
          },
          {
            target: null,
            caption: 'Vendor Completed',
            size: '3%',
            icon: 'AssignmentTurnedInIcon',
            className : 'iconwidth'
          },
          {
            target: 'escrow_num', //'client_reference_num', // COMMENT By ME // TO DO: Escrow number?
            caption: 'Escrow No.',
            size: '10%',
            sortValue: 'client_reference_num',
          },
          {
            target: 'client.name',
            caption: 'Client',
            size : '10%',
            sortValue: [ 'client.name', 'date.ordered' ],
          },
          {
            target: 'date.due',
            caption: 'Due Date',
            size: '10%',
            format: 'Date',
            className : 'datecolumn'
          },
          {
            target : 'admin_assigned.name',
            className : 'orderadmin',
            size : '10%'
          },
          {
            target: null,
            caption: 'Download Document',
            size: '3%',
            icon: 'SimCardDownloadIcon',
            className : 'iconwidth'
          }
        ]}
      />
      <PipelineCollection.Config
        id="closed"
        name="Closed"
        source="lightCycleClosed"
        // link="/admin/orderdetails.php?orderid={{id}}"
        columns={[
          {
            target: 'date.completed',
            caption: 'Date Completed',
            size: '25%',
            format: 'Date',
            sortValue: 'date.completed',
          },
          {target:'property.fulladdress',size: '25%'},
          {
            target: 'escrow_num', //'client_reference_num', // COMMENT By ME // TO DO: Escrow number?
            caption: 'Escrow No.',
            size: '25%',
          },
          {target:'client.name',size: '25%'}
        ]}
      />
      <PipelineCollection.Config
        id="rejected"
        name="Rejected"
        source="lightCycleRejected"
        // link="/admin/orderdetails.php?orderid={{id}}"
        columns={[
           {
            target : 'date.ordered',
            caption : 'Ordered Date',
            size: '110px',
            format: 'Date',
            className : 'datecolumn'
          },
          {
            target : 'property.fulladdress',
             size : '220px'
          },
          {
            target: 'escrow_num', //'client_reference_num', // COMMENT By ME // TO DO: Escrow number?
            caption: 'Escrow No.',
            size: '120px',
          },
          {
            target: 'client.name',
            caption: 'Client',
            size: '120px',
          },
          {
            target:'date.rejected',
            caption:'Rejection Date',
            size: '110px',
            format: 'Date',
            className : 'datecolumn'
          },
          {
            target:'rejection_reason',
            caption:'Reason',
            size : '150px',
            format:'SetLongText'
          },
          {
            target: 'parts',
            format: 'GetFirm',
            caption: 'Firm',
            size: '110px',
          },
          {
            target: 'parts',
            caption: 'Vendor',
            size: '110px',
            format:'GetVendorName'
          },
          {
            target: null,
            caption: 'Rejection Timer',
            format:'RelativeTime',
          }
        ]}
      />

      <PipelineView {...props} />
    </PipelineCollection>
  );
}
