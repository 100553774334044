
import Dashboard from './Dashboard';
import Pipelines from './Pipelines';

export default function AdminMainSubPage () {
  return (
    <>
      <Dashboard sx={{ mb: 2 }} />
      <Pipelines />
    </>
  );
}
