import PropTypes from 'prop-types';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useCallback, useRef, useState, useEffect } from 'react';
import usePipelineCollection from 'common/pipelines/usePipelineCollection';
import { PipelineProvider } from 'common/pipelines/usePipeline';
import PipelineTabs from 'common/pipelines/ui/PipelineTabs';
import PipelineSort from 'common/pipelines/ui/PipelineSort';
import PipelineFilter from 'common/pipelines/ui/PipelineFilter';
import PipelineList from 'common/pipelines/ui/PipelineList';
import PipelineHeader from 'common/pipelines/ui/PipelineHeader';
import PipelineError from 'common/pipelines/ui/PipelineError';
import PipelinePagination from 'common/pipelines/ui/PipelinePagination';
import Row from 'common/ui/Row';
import Column from 'common/ui/Column';
import Typography from '@mui/material/Typography';
import { useOrderDetailModal } from '../OrderDetailsModal';
import OrderDetailsModal from '../OrderDetailsModal';
import AttorneyDetailsModal from '../AttorneyDetailsModal';
import FirmDetailsModal from '../FirmDetailsModal';
import OrderPartsSubPage from "common/pipelines/partList";
import { Api } from "core/api/base";
import Order from "common/models/order";

export default function PipelineView({ routeParam = "pipeline", ...props }) {

  const [openRow, setOpenRow] = useState();
  const [showAttorneyDetails, setShowAttorneyDetails] = useState(false);
  const [showFirmDetails, setShowFirmDetails] = useState(false);
  const [partId, setPartId] = useState(0);
  const [orderTagOptions, setorderTagOptions] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState();
  const navigate = useNavigate();
  const api = (new Order);
  const pipelines = usePipelineCollection();

  const tabs = Array.from(pipelines.list(), ({ id }) => id);

  const [searchParams, setSearchParams] = useSearchParams([
    [routeParam, tabs[0] || '']
  ]);
  let activeTab = searchParams.get(routeParam);

  const paramsRef = useRef();
  paramsRef.current = searchParams;

  if (!tabs.includes(activeTab)) {
    activeTab = tabs[0];
    setTimeout(() => setSearchParams({
      [routeParam]: activeTab
    }));
  }

  const switchTab = useCallback((key) => {
    const newParams = new URLSearchParams(paramsRef.current);
    newParams.set(routeParam, key);
    setSearchParams(newParams);
  }, [routeParam]);

  const activePipeline = pipelines.get(activeTab);
  const makeModalUrl = useOrderDetailModal();
  const onRowClick = useCallback((ev, { row }) => {
    ev.preventDefault();
    navigate(makeModalUrl(row.id));
  });

  const onRowToggle = useCallback((ev, { row }) => {
    ev.preventDefault();
    //navigate(makeModalUrl(row.id));
    setOpenRow(row.id)
  });

  useEffect(() => {
    getOrderTags()
  }, [])

  const getOrderTags = async () => {
    try {
      const result = await api.getOrderTags();
      const { status, tags } = result;

      if (status === "success") {
        setorderTagOptions(tags);
      }
    }
    catch (err) {}
  }

  return (
    <PipelineProvider pipeline={activePipeline}>
      <Column fill {...props}>
        <Row align="bottom" sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <PipelineTabs
            value={activeTab}
            onChange={useCallback((ev, key) => switchTab(key), [ switchTab ])}
          />
          <PipelineFilter/>
          {/* <PipelineSort /> */}
        </Row>
        <PipelineHeader />
        <PipelineError />
        <Column fill>
          <PipelineList
            orderTagOptions = { orderTagOptions }
            onRowClick={onRowClick}
            openRow={openRow}
            onRowToggle={onRowToggle}
            rowDetailRender={(row) => (
              <Column>
                { openRow == row.id && <OrderPartsSubPage setSelectedCompanyId={setSelectedCompanyId} selectedCompanyId={selectedCompanyId} partId={partId} orderid={row.id} activePipeline={activePipeline} setShowFirmDetails={setShowFirmDetails} setShowAttorneyDetails={setShowAttorneyDetails}  setPartId={setPartId}/> }
              </Column>
            )}
          />
        </Column>
        <Row justify="center" sx={{ p: 3, position: 'sticky', bottom: 0 }}>
          <PipelinePagination elevation={12} />
        </Row>
      </Column>
      {showAttorneyDetails ? <AttorneyDetailsModal setShowAttorneyDetails={setShowAttorneyDetails} partid={partId} orderid={openRow}/> : showFirmDetails ? <FirmDetailsModal setSelectedCompanyId={setSelectedCompanyId} setShowFirmDetails={setShowFirmDetails} partid={partId} orderid={openRow} />  : <OrderDetailsModal setShowAttorneyDetails={setShowAttorneyDetails} setPartId={setPartId}/>}
    </PipelineProvider>
  );
}
PipelineView.displayName = 'PipelineTabs';
PipelineView.propTypes = {
  routeParam: PropTypes.string,
};
